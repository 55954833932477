<template>
  <div>
    <validation-observer ref="addProductForm">
      <b-form @submit.prevent class="d-flex flex-column">
        <b-card class="product-card-container">
          <b-row>
            <b-col v-if="vendorsData" class="mb-2" cols="12">
              <!-- Start Dropdown -->
              <b-form-group
                :label="$t('add_product/vendor')"
                label-for="Vendor"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Vendor"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.vendor_id"
                    :disabled="catalogId ? false : true"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-select-option
                      v-for="vendor in vendorsData"
                      :key="vendor.id"
                      :value="vendor.id"
                    >
                      {{ vendor.store_name }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Dropdown -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/store_name')"
                label-for="Name"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Name"
                >
                  <b-form-input
                    v-model="catalogName"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <b-form-group
                :label="$t('add_product/suffix_name')"
                label-for="Suffix_Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Suffix_Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.suffix_name"
                    :disabled="catalogId ? false : true"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/sku')" label-for="SKU">
                <b-form-input
                  v-model="form_data.sku"
                  type="text"
                  :disabled="catalogId ? false : true"
                />
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/price')" label-for="Price">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Price"
                >
                  <b-form-input
                    v-model="form_data.price"
                    type="number"
                    step=".1"
                    :disabled="catalogId ? false : true"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/sale')" label-for="Sale">
                <validation-provider #default="{ errors }" name="Sale">
                  <b-form-input
                    v-model="form_data.sale"
                    type="number"
                    step=".1"
                    :disabled="catalogId ? false : true"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/quantity')"
                label-for="Quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Quantity"
                >
                  <b-form-input
                    v-model="form_data.qty"
                    type="number"
                    :disabled="catalogId ? false : true"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/max_quantity')"
                label-for="Maximum Quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Maximum Quantity"
                >
                  <b-form-input
                    v-model="form_data.max_qty"
                    type="number"
                    :disabled="catalogId ? false : true"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <!-- min stock   -->
            <b-col class="mb-2" cols="12" sm="6">
              <b-form-group
                :label="$t('g.settings/generalSettings/min_stock')"
                label-for="Min stock"
              >
                <validation-provider #default="{ errors }" name="Min stock">
                  <b-form-input
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :disabled="catalogId ? false : true"
                    v-model="form_data.min_stock"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **min stock   -->

            <b-col class="mb-2" cols="12">
              <!-- Start Input -->
              <div class="product-images-box">
                <b-form-group
                  :label="$t('add_product/main_image')"
                  label-for="Main Image"
                >
                  <validation-provider #default="{ errors }" name="Main Image">
                    <div class="main-image">
                      <b-form-file
                        v-model="form_data.mainImage"
                        type="file"
                        accept="image/*"
                        class="main-image-input"
                        :disabled="catalogId ? false : true"
                        :state="errors.length > 0 ? false : null"
                        @change="handleProductMainImage"
                      />
                      <img
                        v-if="productMainImage"
                        class="main-img"
                        :src="productMainImage.path"
                        alt="Add product main image"
                      />
                      <img
                        v-else
                        :src="addImageIcon"
                        alt="Add product main image"
                      />
                    </div>
                    <RecommendationUploadImage
                      v-if="
                        !form_data.mainImage || form_data.mainImage.length == 0
                      "
                      :dimensions="{ width: 1024, height: 1024 }"
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <!-- End Input -->
            </b-col>
            <b-col cols="12">
              <div class="switches-box">
                <b-form-checkbox
                  v-model="form_data.is_visible"
                  name="check-button"
                  switch
                >
                  {{ $t("add_product/is_visiable") }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="form_data.is_free_shipping"
                  name="check-button"
                  switch
                >
                  {{ $t("add_product/is_free_shipping") }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="form_data.is_has_vat"
                  name="check-button"
                  switch
                >
                  {{ $t("g.value_added_tax") }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <div class="btns-product">
          <b-button
            variant="outline-danger"
            class="align-self-end"
            @click="cancel"
          >
            {{ $t("g.cancel") }}
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            class="align-self-end"
            :disabled="catalogId ? false : true"
            @click.prevent="validation()"
          >
            {{ $t("g.products/products_list/add_product") }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
    <image-cropper-component
      :images-array="productMainImage"
      :image-type="true"
      @return-main-image="handeCroppedImage"
      @handel-cancel-main-image="handeCroppedImage"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormSelectOption,
  BFormFile,
  BButton,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";
import ImageCropperComponent from "@/components/shared/ImageCropperComponent/index.vue";

export default {
  components: {
    ImageCropperComponent,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BButton,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormCheckbox,
    BForm,
    RecommendationUploadImage,
  },
  props: ["selectedCatalogData", "handleOverlayLoading"],
  data() {
    return {
      required,
      vendorsData: null,
      productMainImage: null,
      form_data: {
        vendor_id: null,
        suffix_name: null,
        sku: null,
        max_qty: null,
        min_qty: 1,
        qty: null,
        sale: null,
        price: null,
        mainImage: null,
        min_stock: null,
        is_visible: true,
        is_free_shipping: false,
        is_has_vat: true,
      },
      maxQtyActive: false,
      productId: null,
      productSlug: null,
      addExatraIcon: require("@/assets/images/icons/Lead icon.svg"),
      addImageIcon: require("@/assets/images/icons/add-image-icon.svg"),
      addProductImagesIcon: require("@/assets/images/icons/add-product-images.svg"),
    };
  },
  computed: {
    catalogName() {
      this.form_data.suffix_name = this.selectedCatalogData?.name;
      return this.selectedCatalogData?.name;
    },
    catalogId() {
      return this.selectedCatalogData?.id;
    },
  },
  created() {
    this.getVendorsData();
  },
  methods: {
    cancel() {
      for (let index = 0; index < this.form_data.length; index++) {
        this.form_data[index] = null;
      }
      this.$router.push({ name: "products" });
    },
    async getVendorsData() {
      try {
        const response = await this.$http.get("admin/vendors");
        this.vendorsData = response.data.data.filter(
          (item) => item.is_approved
        );
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    handleProductMainImage(event) {
      const file = event.target.files[0];

      if (file) {
        this.productMainImage = { path: URL.createObjectURL(file), file };
        this.$bvModal.show("image-cropper-component");
      }
    },
    handeCroppedImage(file) {
      this.form_data.mainImage = file.file;
      this.productMainImage = { ...this.productMainImage, path: file.path };
    },
    async handleSubmitProduct() {
      try {
        this.$emit("handleOverlayLoading", true);
        const formData = new FormData();
        for (const item in this.form_data) {
          if (this.form_data[item] !== null) {
            if (item === "mainImage") {
              formData.append("images[0][file]", this.form_data[item]);
              formData.append("images[0][is_main]", 1);
            } else if (
              item === "is_visible" ||
              item === "is_free_shipping" ||
              item === "is_has_vat"
            ) {
              formData.append(item, this.form_data[item] ? 1 : 0);
            } else if (item === "sku") {
              this.form_data.sku && formData.append(item, this.form_data[item]);
            } else if (item === "sale") {
              formData.append(
                item,
                this.form_data[item] ? this.form_data[item] : 0
              );
            } else if (item === "max_qty") {
              this.form_data.max_qty &&
                formData.append(item, this.form_data[item]);
            } else {
              formData.append(item, this.form_data[item]);
            }
          }
        }

        formData.append("catalog_id", this.catalogId);
        const res = await this.$http.post("admin/products", formData);
        if (res.status > 299) {
          throw Error();
        }
        this.$helpers.makeToast(
          "success",
          this.$t("productCreatedSuccessfully"),
          res.data.message
        );

        this.$router.push({ name: "products" });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.$emit("handleOverlayLoading", false);
      }
    },
    validation() {
      this.$refs.addProductForm.validate().then((success) => {
        if (success) {
          this.handleSubmitProduct();
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"addProductForm"},[_c('b-form',{staticClass:"d-flex flex-column",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',{staticClass:"product-card-container"},[_c('b-row',[(_vm.vendorsData)?_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/vendor'),"label-for":"Vendor"}},[_c('validation-provider',{attrs:{"name":"Vendor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.vendor_id),callback:function ($$v) {_vm.$set(_vm.form_data, "vendor_id", $$v)},expression:"form_data.vendor_id"}},_vm._l((_vm.vendorsData),function(vendor){return _c('b-form-select-option',{key:vendor.id,attrs:{"value":vendor.id}},[_vm._v(" "+_vm._s(vendor.store_name)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,854173913)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/store_name'),"label-for":"Name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.catalogName),callback:function ($$v) {_vm.catalogName=$$v},expression:"catalogName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/suffix_name'),"label-for":"Suffix_Name"}},[_c('validation-provider',{attrs:{"name":"Suffix_Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.suffix_name),callback:function ($$v) {_vm.$set(_vm.form_data, "suffix_name", $$v)},expression:"form_data.suffix_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/sku'),"label-for":"SKU"}},[_c('b-form-input',{attrs:{"type":"text","disabled":_vm.catalogId ? false : true},model:{value:(_vm.form_data.sku),callback:function ($$v) {_vm.$set(_vm.form_data, "sku", $$v)},expression:"form_data.sku"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/price'),"label-for":"Price"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":".1","disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.price),callback:function ($$v) {_vm.$set(_vm.form_data, "price", $$v)},expression:"form_data.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/sale'),"label-for":"Sale"}},[_c('validation-provider',{attrs:{"name":"Sale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":".1","disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.sale),callback:function ($$v) {_vm.$set(_vm.form_data, "sale", $$v)},expression:"form_data.sale"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/quantity'),"label-for":"Quantity"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.qty),callback:function ($$v) {_vm.$set(_vm.form_data, "qty", $$v)},expression:"form_data.qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/max_quantity'),"label-for":"Maximum Quantity"}},[_c('validation-provider',{attrs:{"name":"Maximum Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.max_qty),callback:function ($$v) {_vm.$set(_vm.form_data, "max_qty", $$v)},expression:"form_data.max_qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/min_stock'),"label-for":"Min stock"}},[_c('validation-provider',{attrs:{"name":"Min stock"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.catalogId ? false : true},model:{value:(_vm.form_data.min_stock),callback:function ($$v) {_vm.$set(_vm.form_data, "min_stock", $$v)},expression:"form_data.min_stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"product-images-box"},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/main_image'),"label-for":"Main Image"}},[_c('validation-provider',{attrs:{"name":"Main Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"main-image"},[_c('b-form-file',{staticClass:"main-image-input",attrs:{"type":"file","accept":"image/*","disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},on:{"change":_vm.handleProductMainImage},model:{value:(_vm.form_data.mainImage),callback:function ($$v) {_vm.$set(_vm.form_data, "mainImage", $$v)},expression:"form_data.mainImage"}}),(_vm.productMainImage)?_c('img',{staticClass:"main-img",attrs:{"src":_vm.productMainImage.path,"alt":"Add product main image"}}):_c('img',{attrs:{"src":_vm.addImageIcon,"alt":"Add product main image"}})],1),(
                      !_vm.form_data.mainImage || _vm.form_data.mainImage.length == 0
                    )?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1024, height: 1024 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"switches-box"},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.form_data.is_visible),callback:function ($$v) {_vm.$set(_vm.form_data, "is_visible", $$v)},expression:"form_data.is_visible"}},[_vm._v(" "+_vm._s(_vm.$t("add_product/is_visiable"))+" ")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.form_data.is_free_shipping),callback:function ($$v) {_vm.$set(_vm.form_data, "is_free_shipping", $$v)},expression:"form_data.is_free_shipping"}},[_vm._v(" "+_vm._s(_vm.$t("add_product/is_free_shipping"))+" ")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.form_data.is_has_vat),callback:function ($$v) {_vm.$set(_vm.form_data, "is_has_vat", $$v)},expression:"form_data.is_has_vat"}},[_vm._v(" "+_vm._s(_vm.$t("g.value_added_tax"))+" ")])],1)])],1)],1),_c('div',{staticClass:"btns-product"},[_c('b-button',{staticClass:"align-self-end",attrs:{"variant":"outline-danger"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("g.cancel"))+" ")]),_c('b-button',{staticClass:"align-self-end",attrs:{"type":"submit","variant":"primary","disabled":_vm.catalogId ? false : true},on:{"click":function($event){$event.preventDefault();return _vm.validation()}}},[_vm._v(" "+_vm._s(_vm.$t("g.products/products_list/add_product"))+" ")])],1)],1)],1),_c('image-cropper-component',{attrs:{"images-array":_vm.productMainImage,"image-type":true},on:{"return-main-image":_vm.handeCroppedImage,"handel-cancel-main-image":_vm.handeCroppedImage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }